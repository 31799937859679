// 阿里矢量图标库配置
// let iconfontVersion = ['567566_pwc3oottzol'];
// let iconfontUrl = `//at.alicdn.com/t/font_$key.css`;

let baseUrl = 'https://zycf999.com';
let codeUrl = `${baseUrl}/code`
const env = process.env

export {
    baseUrl,
    // iconfontUrl,
    // iconfontVersion,
    codeUrl,
    env
}
